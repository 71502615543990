.modal {
  background-color: $white;
  padding: 70px 100px;
  color: $black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  max-width: 100%;
  max-height: 95vh;
  overflow: auto;
  opacity: 0;
  transition: opacity 0.4s 0.2s;
  pointer-events: none;
  display: none;
  
  &.active {
    display: block;
    pointer-events: all;
    opacity: 1;
  }

  @media (max-width: 850px) {
    padding: 40px 20px 20px;
  }

  &-close {
    background-image: url(../img/close.png);
    background-size: cover;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 48px;
    right: 41px;

    @media (max-width: 850px) {
      top: 10px;
      right: 10px;
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.63);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s;
    z-index: 100;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-title {
    font-size: 36px;
    font-family: 'Bebas Neue';
    font-weight: bold;
  }

  &-text {
    margin-top: 50px;
    min-width: 400px;
    max-width: 600px;

    p {
      margin-top: 0;
      margin-bottom: 0.5em;
    }

    @media (max-width: 850px) {
      margin-top: 20px;
      min-width: 200px;
      max-width: 100%;
    }
  }
}

.form {
  &-line {
    margin-bottom: 25px;

    label {
      display: block;
      margin-bottom: 4px;
    }

    input {
      display: block;
      width: 100%;
      background-color: #e3e5e7;
      border: none;
      line-height: 45px;
      height: 45px;
      padding: 0 15px;
      font-size: 18px;
    }
  }

  &-btn {
    margin-top: 50px;

    @media (max-width: 850px) {
      margin-top: 20px;
    }
  }
}