.overlay {
  background-image: url('../../assets/overlay.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top center;
  opacity: 0.2;
  z-index: 100;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}