.work {
  background-image: url('../img/work-bg.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  .container {
    padding-top: 55px;
    padding-bottom: 135px;
  }

  &-slider {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-left {
      width: 66.6%;

      @media (max-width: 992px) {
        width: 100%;
      }
    }

    &-right {
      width: 33.4%;
      display: flex;
      flex-direction: column;
      padding: 8.8% 0 4.8%;

      @media (max-width: 1230px) {
        padding: 8.8% 0 20px 30px;
      }

      @media (max-width: 992px) {
        padding: 50px 0 20px;
        width: 100%;
      }
    }

    &-caption {
      font-size: 18px;
      position: absolute;
      top: 0;
      left: 0;
      right: 25%;
      opacity: 0;
      transition: opacity 1s;

      @media (max-width: 992px) {
        right: 0;
      }

      p {
        margin-top: 0;
      }

      &.active {
        transition: opacity 1s 0.4s;
        opacity: 1;
        position: relative;
      }
    }

    &-captions {
      position: relative;
      flex-basis: 50%;
      padding-right: 25%;
      
      @media (max-width: 992px) {
        padding-right: 0;
        margin: 0 60px;
        min-height: 150px;
        order: 2;
      }
      @media (max-width: 580px) {
        margin: 60px 0 0;
      }
    }

    &-control {
      margin-top: auto;
      display: flex;
      align-items: center;
      position: relative;

      @media (max-width: 992px) {
        order: 1;
      }
    }

    &-digits {
      margin-left: auto;
      font-family: 'Bebas Neue';
      font-weight: bold;
      font-size: 36px;
      margin-right: 30px;

      @media (max-width: 992px) {
        display: none;
      }
    }

    &-arrows {
      display: flex;

      @media (max-width: 992px) {
        width: 100%;
        margin-top: 20px;
        justify-content: space-between;
        position: absolute;
      }
    }

    &-arrow {
      border: 2px solid $white;
      height: 53px;
      width: 53px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        border-top: 2px solid $white;
        border-left: 2px solid $white;
        height: 20px;
        width: 20px;
        transform: rotate(-45deg) translate(5px, 5px);
      }

      &--right {
        margin-left: -2px;
        padding-right: 8px;
        transform: scaleX(-1)
      }
    }

    &-imgs {
      overflow: hidden;
      width: 100%;
      height: 450px;
      position: relative;

      @media (max-width: 1230px) {
        height: 398px;
      }

      @media (max-width: 768px) {
        height: auto;
      }
    }

    &-img {
      max-width: 100%;
      position: absolute;
      opacity: 0;
      transition: opacity 1s;
      top: 0;
      left: 0;
      
      &.active {
        transition: opacity 1s 0.4s;
        opacity: 1;
        position: relative;
      }
    }
  }
}