.hero {
  position: relative;
  overflow: hidden;
  
  &:before {
    content: '';
    background-image: url('../img/hero.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    animation: scale 25s infinite;
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }

  & > * {
    position: relative;
  }

  &-block {
    min-height: 500px;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 23.6%;
    padding-left: 7.8%;
    padding-right: 6.2%;
    padding-bottom: 9%;

    @media (max-width: 1020px) {
      flex-direction: column;
    }
    @media (max-height: 730px) {
      padding-top: 15.6%;
    }
  }

  &-text {
    padding-right: 28%;

    h1 {
      margin: 0px;
      font-size: 102px;

      @media (max-width: 1400px) {
        font-size: 72px;
      }

      @media (max-width: 600px) {
        font-size: 46px;
      }

      @media (max-height: 420px) {
        font-size: 40px;
      }

    }
  }

  &-action {
    width: 100%;
    text-align: right;

    @media (max-width: 1020px) {
      margin-top: 40px;
    }
  }
}

.btn {
  background-color: $yellow;
  color: $black;
  text-transform: uppercase;
  font-size: 30px;
  font-family: 'Bebas Neue';
  border: none;
  padding: 13px 44px;
  font-weight: bold;
  transition: background-color 0.2s;

  &:hover {
    background-color: #ffe379;
  }

  @media (max-width: 820px) {
    font-size: 22px;
  }
}