$orange: #DD5600;
$yellow: #ffcd11;
$green: #12A647;
$turquoise: #07927F;
$blue: #3273DC;
$purple: #7e5ea3;
$red: #F70032;
$cyan: #209CEE;
$black: #000;
$white: hsl(0, 0%, 100%);
$light: hsl(0, 0%, 96%);
$dark: #25272c;
$grey: #7c7d80;
$darkGrey: #2c2e33;

$primary: $turquoise;
$link: $blue;
$info: $cyan;
$success: $green;
$warning: $yellow;
$danger: $red;

$colorList: (black: $black, white: $white, light: $light, dark: $dark, primary: $primary, link: $link, info: $info, success: $success, warning: $warning, danger: $danger);

$fontColors: (black: $black, white: $white, light: $light, dark: $dark, primary: $primary, link: $link, info: $info, success: $success, warning: $warning, danger: $danger, purple: $purple, orenge: $orange);