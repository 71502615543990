.header {
  display: flex;
  justify-content: space-between;
  padding: 70px 61px 50px 70px;
  position: absolute;
  width: 100%;
  z-index: 2;

  @media (max-width: 1370px) {
    padding: 30px;
  }

  &-menu-btn {
    margin-right: 40px;

    span {
      display: block;
      height: 3px;
      background-color: $white;
      width: 30px;
      margin-top: 5px;
      transition: background-color 0.2s;
    }

    &:hover {
      span {
        background-color: $yellow;
      }
    }

    @media (min-width: 1025px) {
      display: none;
    }
  }

  &-logo {
    margin-right: auto;
    margin-top: -10px;

    img {
      max-width: 100%;
    }
  }

  &-contacts {
    flex-basis: 18.7%;
    margin-top: -4px;
    line-height: 1.6;

    @media (max-width: 1024px) {
      flex-basis: auto;
      padding-right: 20px;
    }
    @media (max-width: 640px) {
      display: none;
    }
  }

  &-nav {
    flex-basis: 41%;

    @media (max-width: 1400px) {
      flex-basis: auto;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &-lang {
    font-family: 'Bebas Neue';
    font-weight: bold;
    font-size: 18px;

    a {
      margin-left: 20px;
      opacity: 0.2;
      transition: opacity 0.2s, color 0.2s;

      &:first-child {
        margin-left: 0;
      }

      &.active,
      &:hover {
        opacity: 1;
      }
    }

    @media (max-width: 640px) {
      display: none;
    }
  }
}

.nav {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      margin-right: 42px;

      a {
        text-transform: uppercase;
      }
    }
  }
}

.drawer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $dark;
  padding: 65px 20px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: space-around;
  pointer-events: none;
  transition: opacity 0.2s;
  opacity: 0;
  pointer-events: none;
  background-image: url('../img/hero.png');
  background-size: cover;
  background-position: center;
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);

  }

  * {
    opacity: 0;
    transition: opacity 0.2s 0.2s;
    position: relative;
    z-index: 2;
  }

  &.active {
    opacity: 1;
    pointer-events: all;

    * {
      opacity: 1;
    }
  }

  &-close {
    position: absolute;
    right: 30px;
    top: 20px;
    font-size: 50px;
    transform: rotate(45deg);
    line-height: 1;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: $yellow;
    }
  }

  &-logo {
    text-align: center;
  }

  &-nav {
    ul {
      display: block;

      li {
        display: block;
        text-align: center;
        margin: 20px 0;

        a {
          line-height: 1.2;
          font-size: 18px;
        }
      }
    }
  }

  &-contacts {
    text-align: center;
    line-height: 1.6;
  }

  &-socials {
    margin-top: 15px;
    text-align: center;
  }
}

.soc {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &--fb {
    background-image: url(../img/soc-1.svg);
  }
  &--yt {
    background-image: url(../img/soc-2.svg);
  }
  &--ins {
    background-image: url(../img/soc-3.svg);
  }
}
