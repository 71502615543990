.services {
  position: relative;

  &-grid {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
  }
}

.section-title {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}

.service {
  &-item {
    position: relative;
    width: 375px;
    height: 229px;
    background-color: $darkGrey;
    margin: 0 15px 30px;
    overflow: hidden;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      z-index: 2;
    }

    &:after {
      bottom: 0;
      right: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid $dark;
      border-left: 15px solid transparent;
      border-bottom: 15px solid $dark;
    }
    &:before {
      top: 0;
      left: 0;
      border-right: 15px solid transparent;
      border-top: 15px solid $dark;
      border-bottom: 15px solid transparent;
      border-left: 15px solid $dark;
    }

    &:hover {
      .service-icon {
        img:nth-child(2) {
          opacity: 1;
        }
      }

      .service-title {
        color: $white;
      }

      .service-img {
        opacity: 1;
        transform: scale(1.1);
      }

      .service-digit {
        color: #2c2e33;
      }
    }
  }

  &-img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s, transform 2s 0.2s;

    @media (max-width: 768px) {
      opacity: 1;
    }
  }

  &-icon {
    position: absolute;
    left: 45px;
    top: 35px;

    img {
      &:nth-child(2) {
        position: absolute;
        opacity: 0;
        transition: 0.2s;
        left: 0;
        top: 0;
      }
    }
  }

  &-title {
    position: absolute;
    bottom: 20px;
    left: 40px;
    right: 35px;
    font-size: 30px;
    line-height: 1;
    color: $yellow;
    font-family: 'Bebas Neue';
    font-weight: bold;
    transition: color 0.2s;
    z-index: 2;
  }

  &-digit {
    position: absolute;
    font-size: 150px;
    color: $dark;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    bottom: 0;
    line-height: 90px;
    right: 23px;
    transition: color 0.3s;
  }
}