.footer {
  background-color: $black;
  padding-top: 50px;
  padding-bottom: 70px;
  line-height: 1.4;

  &-row {
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;

    &> * {
      margin-top: 10px;

      @media (max-width: 992px) {
        margin-top: 20px;
      }
    }
  }

  &-soc {
    text-align: center;

    @media (max-width: 992px) {
      text-align: right;
    }

    @media (max-width: 576px) {
      text-align: left;
    }

    .soc {
      margin: 0 6px;
    }
  }

  &-creators {
    text-align: right;

    @media (max-width: 576px) {
      text-align: left;
    }
  }
}