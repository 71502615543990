.about {
  position: relative;
  padding-top: 1px;

  &:before {
    content: '';
    background-image: url('../img/about-bg.png');
    background-size: 100% auto;
    background-repeat: repeat-y;
    background-position: bottom center;
    position: absolute;
    top: 0;
    left: 27%;
    width: 218px;
    bottom: 100%;
    
  }

  .container {
    position: relative;
  }

  &-title {
    font-size: 60px;
    text-transform: uppercase;
    padding-left: 33%;
    margin: 0.95em 0 0.62em;

    @media (max-width: 768px) {
      padding-left: 0;
      text-align: center;
    }
  }

  &-text {
    font-size: 18px;
    color: $grey;
    width: 67%;
    line-height: 1.7;
    padding-right: 12%;
    padding-left: 0;

    p {
      margin-top: 0;
      margin-bottom: 1.47em;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &-cols {
    display: flex;
    flex-wrap: wrap;
  }

  &-numbers {
    width: 33%;

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &-number {
    text-align: center;
    font-weight: bold;
    margin-bottom: 4.5em;
    flex: 1 0;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 200px;

    &-digit {
      color: $yellow;
      font-family: 'Bebas Neue';
      font-size: 100px;
      line-height: 1;
    }

    &-title {
      font-weight: bold;
      font-size: 18px;
    }

  }

  &.scrolled {
    &:before {
      transition: bottom  1.8s 0.8s;
      bottom: -112px;
    }
  }
  &.scrolled {
    &:before {
      transition: bottom  1.8s 0.8s;
      bottom: -112px;
    }
  }
}

.slide-in {
  &-bottom,
  &-left,
  &-right,
  &-top {
    transition: transform 0.6s, opacity 0.4s;
    opacity: 0;

    &:nth-child(2) {
      transition-delay: 0.2s;
    }
    &:nth-child(3) {
      transition-delay: 0.4s;
    }
    &:nth-child(4) {
      transition-delay: 0.6s;
    }
    &:nth-child(5) {
      transition-delay: 0.8s;
    }
    &:nth-child(6) {
      transition-delay: 1s;
    }
    &:nth-child(7) {
      transition-delay: 1.2s;
    }
    &:nth-child(8) {
      transition-delay: 1.4s;
    }
    &:nth-child(9) {
      transition-delay: 1.6s;
    }
  }
  &-bottom {
    transform: translateY(-50px);
  }
  
  &-top {
    transform: translateY(50px);
  }
  &-right {
    transform: translateX(-50px);
  }
  &-left {
    transform: translateX(50px);
  }
}

.scrolled {
  .slide-in {
    &-bottom,
    &-left,
    &-right,
    &-top {
      opacity: 1;
      transform: translateY(0)
    }
  }
}

.slide-in {
  &-bottom,
  &-left,
  &-right,
  &-top {
    &.active {
      opacity: 1;
      transform: translateY(0)
    }
  }
}

