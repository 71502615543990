@import "variables";

@font-face {
    font-family: 'Gotham Pro';
    src: url('../assets/fonts/GothaProReg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro';
    src: url('../assets/fonts/GothaProBol.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Bebas Neue';
    src: url('../assets/fonts/BebasNeueBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('../assets/fonts/BebasNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@each $name, $value in $colorList {
  .has-background-#{$name} {
    background-color: $value;
  }
}

@each $name, $value in $fontColors {
  .has-text-#{$name} {
    color: $value;
  }
}

html, body {
  padding: 0;
  margin: 0;
  max-width: 100%;
  background-color: $dark;
  color: $white;
  font-family: 'Gotham Pro', sans-serif;
  font-size: 16px;
  min-height: 100%;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', 'Gotham Pro';
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    text-decoration: underline;
    color: $yellow;
  }
}

*,
*:after,
*:before {
  box-sizing: border-box;
}